<template>
    <div class="cardContainer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {

    },
    data() {
        return {
        };
    },
    computed: {},
    created() {
    },
    methods: {
    },
};

</script>
<style>
.cardContainer {
    background: transparent linear-gradient(143deg, #BC00BC 0%, #06006000 100%);
    border: 1px solid #FFFFFF4D;
    border-radius: 15px;
    padding: 4%;
    justify-content: center;
    display: flex;
    align-items: center;
}

slot {
    align-self: center;
}
</style>