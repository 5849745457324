<template lang="html">
  <div class="home" @click.right.prevent>
    <div class="home-container">
      <HeroHome />
      <UnderHeroSection />
    </div>
  </div>
</template>
<script>

import HeroHome from "../components/Home/HeroHome.vue";
import UnderHeroSection from "../components/Home/UnderHeroSection.vue";



export default {
  name: "HomePage",
  components: {
    HeroHome,
    UnderHeroSection
  },
  mounted() {
    this.$route.meta.title = "Home"
    this.$route.meta.description = " "
    this.$route.meta.keywords = " "
  },
  data() {
    return {
      items: [{
        text: "Home",
        to: "#"
      }]
    }
  },
};

</script>
<style lang="css">
.container-retro{
  max-width: 100% !important;
}
</style>
