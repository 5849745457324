<template>
  <div class="mainDiv">
    <div
      data-aos="zoom-in-up"
      data-aos-once="true"
      data-aos-duration="1250"
      class="Buttons element-height"
    >
      <!-- <PrimaryButton
        type="primary"
        label="Subscribe"
        :onClick="goToSubscribeSection"
      /> -->
      <a href="#contact" class="primary link-hero"> Subscribe </a>
      <a
        href="https://discord.com/invite/HvCHwyNrzn"
        target="_blank"
        class="secondary link-hero"
        >Discord</a
      >
    </div>

    <div class="waves">
      <Wave v-if="!this.$store.state.isLightTheme" />
      <WaveLight v-if="this.$store.state.isLightTheme" />
    </div>
    <div
      class="background"
      :class="{ lightBackground: this.$store.state.isLightTheme }"
    >
      <div class="circles">
        <figure
          class="circle"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle1"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle2"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
      </div>
      <div class="videoContainer" id="about">
        <h4
          v-if="!showElement"
          data-aos-once="true"
          data-aos="zoom-in"
          data-aos-duration="1250"
          class="knowYourVerse"
          :class="{ knowYourVerseLight: this.$store.state.isLightTheme }"
        >
          KNOW YOUR VERSE
        </h4>
        <h4
          v-if="showElement"
          class="knowYourVerse"
          :class="{ knowYourVerseLight: this.$store.state.isLightTheme }"
        >
          KNOW YOUR VERSE
        </h4>
        <h4
          ref="about"
          data-aos="zoom-in"
          data-aos-once="true"
          class="RETROVERSE"
          data-aos-duration="1250"
        >
          RETROVERSE
        </h4>
        <!-- <video
          data-aos-once="true"
          data-aos-easing="ease-in"
          data-aos="zoom-in"
          data-aos-duration="1250"
          class="video"
          controls
          width="60%"
        >
          <source src="../../assets/img/Animated.mp4" type="video/mp4" />
        </video> -->
        <img
          src="../../assets/img/coming.png"
          class="video"
          style="width: 75%; object-fit: cover"
        />
        <!-- <div class="underVideoSection display-non-element">
          <b-row>
            <b-col cols="12" lg="6" md="6">
              <div
                data-aos="zoom-in"
                data-aos-duration="1250"
                data-aos-once="true"
                class="firstColumn"
              >
                <h5
                  class="title font-style-retro-title"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  CREATE
                </h5>
                <p
                  class="detaiLText text-justify font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  RetroVerse is the perfect place for developers who want to
                  make their childhood dreams come true!
                </p>
                <LogoLight v-if="this.$store.state.isLightTheme" />
                <p
                  class="detaiLText text-justify font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  You will find everything you need for your retro project here.
                  From a game builder to resources, we have it all!
                </p>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6">
              <div
                data-aos="zoom-in"
                data-aos-once="true"
                class="secondColumn"
                data-aos-duration="1250"
              >


                <p
                  class="detaiLText second text-justify font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  Take this opportunity to unleash your creativity and
                  nostalgia!
                </p>
                <PrimaryButton
                  class="PrimaryButton"
                  type="third"
                  :label="'Create'"
                  :onClick="goToCreatePage"
                />
              </div>
            </b-col>
          </b-row>
        </div> -->
        <div>
          <b-row>
            <b-col cols="12">
              <div class="display-element-responsive p-20">
                <h5
                  class="title font-style-retro-title text-center"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  CREATE
                </h5>
                <p
                  class="detaiLText text-center font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  RetroVerse is the perfect place for developers who want to
                  make their childhood dreams come true!
                </p>
                <LogoLight v-if="this.$store.state.isLightTheme" />
                <p
                  class="detaiLText text-center font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  You will find everything you need for your retro project here.
                  From a game builder to resources, we have it all!
                </p>
                <p
                  class="detaiLText second text-center font-style-retro-medium"
                  :class="{ titleLight: this.$store.state.isLightTheme }"
                >
                  Take this opportunity to unleash your creativity and
                  nostalgia!
                </p>
                <PrimaryButton
                  class="PrimaryButton"
                  type="third"
                  :label="'Create'"
                  :onClick="goToCreatePage"
                />

                <div class="mb-100 mt-50">
                  <h5
                    class="title font-style-retro-title text-center"
                    :class="{ titleLight: this.$store.state.isLightTheme }"
                  >
                    PLAY
                  </h5>
                  <p
                    class="detaiLText second text-center font-style-retro-medium"
                  >
                    RetroVerse is a platform that will amaze you. Explore the
                    HUB to discover new adventures, customize your Avatar with
                    endless options, and join special events with the community
                    to make awesome memories.
                  </p>
                  <PrimaryButton
                    class="PrimaryButton float-right mt-25"
                    type="third"
                    :label="'Play'"
                    :onClick="goToPlayPage"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="playerGlobal display-non-element">
        <div class="createCotainer">
          <div class="d-flex-retro">
            <div
              data-aos="slide-right"
              data-aos-once="true"
              class="Assets"
              data-aos-duration="1250"
            >
              <span class="AssetStore">Create</span>
              <span
                class="Ultimage create-text font-style-retro-medium text-justify"
              >
                RetroVerse is the perfect place for developers who want to make
                their childhood dreams come true ! <br />
                You will find everything you need for your retro project here.
                From a game builder to resources, we have it all ! <br />
                Take this opportunity to unleash your creativity and nostalgia
                !</span
              >
              <div class="w-80">
                <PrimaryButton
                  class="PrimaryButton float-right mt-25"
                  type="third"
                  :label="'Create'"
                  :onClick="goToCreatePage"
                />
              </div>
            </div>
            <div>
              <img
                class="discoverGames"
                data-aos="slide-right"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/discovergames-3img.gif"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="playerGlobal display-non-element">
        <div class="playerCotainer border-light-player">
          <figure class="circle3" />
          <img
            data-aos="slide-left"
            data-aos-once="true"
            data-aos-duration="1250"
            src="../../assets/img/player.png"
            class="player"
          />
          <div
            data-aos="slide-left"
            data-aos-once="true"
            class="Assets"
            data-aos-duration="1250"
          >
            <span class="AssetStore">PLAY</span>
            <span
              class="Ultimage play-text font-style-retro-medium text-justify"
              >RetroVerse is a platform that will amaze you. Explore the HUB to
              discover new adventures, customize your Avatar with endless
              options, and join special events with the community to make
              awesome memories.</span
            >
            <div class="w-50">
              <PrimaryButton
                class="PrimaryButton float-right mt-25"
                type="third"
                :label="'Play'"
                :onClick="goToPlayPage"
              />
            </div>
          </div>
          <!-- <div
            data-aos="slide-left"
            data-aos-once="true"
            class="cards"
            data-aos-duration="1250"
          >
            <Card>
              <Axe class="rotateY" />
            </Card>
            <Card>
              <Balloon class="rotateY" />
            </Card>
            <Card>
              <Coin class="rotateY" />
            </Card>
            <Card>
              <Tree class="rotateY" />
            </Card>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Wave from "../../assets/svg/wave.svg";
import WaveLight from "../../assets/svg/waveLight.svg";
import Axe from "../../assets/svg/axe.svg";
import Coin from "../../assets/svg/coin.svg";
import Tree from "../../assets/svg/tree.svg";
import Balloon from "../../assets/svg/balloon.svg";
import WhiteLogo from "../../assets/svg/Logo.svg";
import LogoLight from "../../assets/svg/logoLight.svg";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import Card from "../Card/Card.vue";

export default {
  name: "UnderHeroSection",
  components: {
    Wave,
    WhiteLogo,
    PrimaryButton,
    Card,
    Tree,
    Axe,
    Balloon,
    Coin,
    WaveLight,
    LogoLight,
  },
  data() {
    return {
      showElement: null,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.showElement = !this.showElement;
  },
  methods: {
    about() {
      this.$refs["about"].scrollIntoView({ behavior: "smooth" });
    },
    goToCreatePage() {
      this.$router.push({ name: "assetStore" });
    },
    goToPlayPage() {
      this.$router.push({ name: "player" });
    },
    goToSubscribeSection() {
      this.$router.push({ path: "/", hash: "#contact" });
    },
  },
};
</script>
<style lang="css">
.rotateY:hover {
  transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
  transform-origin: center;
  -moz-transform-origin: center;
  -webkit-transform-origin: center;
  transition: 2s ease;
  -moz-transition: 2s ease;
  -webkit-transition: 2s ease;
}

.mainDiv {
  position: relative;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.waves {
  margin-top: -17%;
}

.Assets {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.AssetStore {
  background: transparent linear-gradient(181deg, #ff1919 0%, #6012e8 100%);
  font-family: Peace Sans;
  font-size: 43px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 6% 0;
}

.second {
  width: 100%;
}

.PrimaryButton {
  margin: 0 !important;
}

.underVideoSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 56px;
  padding: 0 20%;
}

.videoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.titles {
  display: flex;
  justify-content: space-around;
  margin: 56px 0 0 -60px;
}

.titleLight {
  color: #000748 !important;
}

.title {
  text-align: left;
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.circles {
  position: absolute;
}

.video {
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  object-fit: none;
}

.circleLight {
  background: transparent linear-gradient(180deg, #e697ff 0%, #d9ddff00 100%) 0%
    0% !important;
  opacity: 0.15 !important;
}

.circle {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%);
  opacity: 0.5;
  border-radius: 50%;
  top: 620px;
  left: -173.95562744140625px;
  width: 800px;
  height: 500px;
}

.circle1 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #06009f 100%);
  opacity: 0.4;
  right: 142px;
  position: absolute;
  width: 486px;
  height: 430px;
  position: absolute;
  border-radius: 500%;
}

.circle3 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #060060 100%);
  opacity: 0.4;
  filter: blur(10px);
  right: 50px;
  position: absolute;
  bottom: -24px;
  width: 23%;
  height: 20%;
  border-radius: 500%;
}

.RETROVERSE {
  font-size: 60px;
  background: transparent linear-gradient(184deg, #ff1919 0%, #6012e8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 80%;
  letter-spacing: 0.2rem;
  font-family: Peace Sans;
  text-align: center;
}

.circle2 {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.35;
  border-radius: 50%;
  top: 930px;
  left: 674px;
  width: 1121px;
  height: 1121px;
  right: 50px;
}

.lightBackground {
  background-color: #fff !important;
}

.background {
  background-color: #000c4a;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -1%;
  flex-direction: column;
}
.createCotainer {
  background: transparent linear-gradient(302deg, #bc00bc 0%, #060060 100%);
  border-radius: 0px 100px 100px 0px;
  width: 75%;
  /* height: 28%; */
  right: 0px;
  align-self: flex-start;
  bottom: 0px;
  margin-top: 70px;
  padding: 50px 50px 25px 90px;
  z-index: 99;
}
.element-height {
  height: 100px;
  z-index: 10;
  position: relative;
}
.element-height button {
  height: 53%;
}
.btn-coundfunding span {
  display: flex;
  justify-content: center;
  align-content: center;
}
.play-text {
  width: 50%;
  text-align: justify;
  color: #fff !important;
}
.create-text {
  width: 90%;
  text-align: justify;
  color: #fff !important;
}
.link-hero {
  height: 53% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font: normal normal normal 16px Lato;
  font-weight: 600;
}

.playerCotainer {
  background: transparent linear-gradient(302deg, #bc00bc 0%, #060060 100%);
  border-radius: 100px 0px 0px 0px;
  width: 75%;
  /* height: 28%; */
  right: 0px;
  align-self: flex-end;
  bottom: 0px;
  margin-top: 70px;
  padding: 120px 0 75px 90px;
}
.lightBackground .border-light-player {
  border-radius: 100px 0px 0px 100px;
}
.discoverGames {
  width: 100% !important;
  height: auto;
}
</style>
