import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from "../views/Layout.vue";
import Home from "../views/Home.vue";
import AboutUs from "../views/AboutUs.vue";
import NotFound from "../views/NotFound.vue";
import Player from '../views/PLayer.vue'
import AssetStore from '../views/AssetStore.vue'
import GeneralCondition from '../views/GeneralCondition.vue'
import Contact from '../views/Contact.vue'
import Ambassador from '../views/Ambassador.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          pageTitle: 'Home Page',
          breadcrumb: [
            {
              text: 'Home Page',
              active: true,
            },
          ],
        },
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
          pageTitle: 'Contact Page',
          breadcrumb: [
            {
              text: 'Contact Page',
              active: true,
            },
          ],
        },
      },
      {
        path: "/Ambassador",
        name: "Ambassador",
        component: Ambassador,
        meta: {
          pageTitle: 'Ambassador Page',
          breadcrumb: [
            {
              text: 'Ambassador Page',
              active: true,
            },
          ],
        },
      },
      // {
      //   path: "/about-us",
      //   name: "AboutUs",
      //   component: AboutUs,
      //   meta: {
      //     pageTitle: 'AboutUs Page',
      //     breadcrumb: [
      //       {
      //         text: 'AboutUs Page',
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        path: "/general-condition",
        name: "general-condition",
        component: GeneralCondition,
        meta: {
          pageTitle: 'General-condition Page',
          breadcrumb: [
            {
              text: 'General-condition Page',
              active: true,
            },
          ],
        },
      },
      {
        path: "/player",
        name: "player",
        component: Player,
        meta: {
          pageTitle: 'player',
          breadcrumb: [
            {
              text: 'player',
              active: true,
            },
          ],
        },
      },
      {
        path: "/assetStore",
        name: "assetStore",
        component: AssetStore,
        meta: {
          pageTitle: 'assetStore',
          breadcrumb: [
            {
              text: 'assetStore',
              active: true,
            },
          ],
        },
      },
      {
        path: "*",
        name: "NotFound",
        component: NotFound,
        meta: {
          pageTitle: 'Not found',
          breadcrumb: [
            {
              text: 'Not found',
              active: true,
            },
          ],
        },
      }
    ]
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth'
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
