<template>
  <div
    id="container"
    class="home-content pt-250 second-padding"
    :class="{ lightThemed: this.$store.state.isLightTheme }"
  >
    <b-row>
      <b-col col lg="12" md="12" sm="12" cols="12" class="text-center">
        <h3
          data-aos="zoom-in-down"
          data-aos-duration="1250"
          data-aos-once="true"
          class="header-subtitle"
        >
          LET'S START AGAIN
        </h3>
        <h2
          data-aos="zoom-in-down"
          data-aos-duration="1250"
          data-aos-once="true"
          class="home-content-h1"
        >
          RETROVERSE
        </h2>
        <p
          data-aos="zoom-in-up"
          data-aos-duration="1250"
          data-aos-once="true"
          class="home-content-p font-style-retro-header"
        >
          RetroVerse is the ultimate Retro platform that lets you create
          and play amazing games with retro graphics! 
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  name: "HeroHome",
  components: { PrimaryButton },
  data() {
    return {};
  },

  mounted() {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="css">
.home-content-h1 {
  font-size: 110px;
  color: #fff;
  line-height: 90%;
  letter-spacing: 0.2rem;
  text-shadow: rgba(255, 255, 255, 1) 0px 0px 49px;
  font-family: Peace Sans;
}

.lightThemed {
  background-image: url("../../assets/img/light_bg.png") !important;
}

.home-content {
  background-image: url("../../assets/img/dark_bg.png");
  background-position: center;
  background-size: cover;
}

</style>
