<template lang="html">
  <nav class="navbar-section">
    <div :class="getClass()">
      <b-navbar class="nav" toggleable="lg" type="dark">
        <b-navbar-brand href="/"
          ><img src="../assets/img/logo.png" alt="Retroverse logo" />
          <router-link to="/" class="logo-text">RETROVERSE</router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav avbar-nav class="ml-auto ul-navbar">
            <a href="/#about" class="buttonText"> About</a>
            <router-link to="/assetStore" class="buttonText"> Create</router-link>
            <router-link to="/player" class="buttonText"> Play</router-link>
            <!-- <router-link to="/contact" class="buttonText"> Contact</router-link> -->
            <a href="#contact" class="subscribeButton"> Subscribe</a>
            <!-- <router-link to="Ambassador" class="subscribeButton" > Ambassador</router-link> -->
           <!-- ambassadorButton -->
            <!-- <router-link to="" class="subscribeButton"  v-b-modal.modal-center> Crowdfunding</router-link> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    
    <b-modal id="modal-center" size="lg" hide-footer hide-header centered title="BootstrapVue">
      <h5 class="title-model font-style-retro-title-xl" >
       Crowdfunding
      </h5>
      <p class="my-4 font-style-retro-medium "> Our goal is to make Retro shine like never before, and to support the amazing retro developers and the passionate retro community that are spread all over the internet.<br> We want to create a new wave of Retro excitement, full of innovations and possibilities.<br>
Let's build the new era of Retro together !</p>
        <div class="dl-center">
          <div>
            <router-link class="btn-kickstarter" to="/"><img class="img-model" src="../assets/img/CF/Kickstarter_logo_2019.svg.png"></router-link>
          </div>                 </div>
    </b-modal> 

  </nav>
</template>
<script>
export default {
  name: "CommonNavbar",
  data() {
    return {
      activeItem: 0,
      Home: false,
    };
  },
  created() {},
  mounted() {
    this.Home = this.$route.meta.title == "Home";
  },
  methods: {
    getClass() {
      return "nav-bar-style navbar-transparent";
    },
  },
};
</script>
<style lang="css">
.btn-kickstarter{
  background: transparent linear-gradient(173deg, #4015d5 0%, #e32525 100%) 0%
    0% no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  padding: 20px 40px;
}
.img-model {
  width: 30%;
  height: auto;
}
.btn-kickstarter:hover{
    background-position: 100% 0;
}
@media (min-width: 992px) {
  #modal-center .modal-lg,
  #modal-center .modal-xl {
    max-width: 850px;
  }
}

.modal-content {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 150%) 0%
    0% no-repeat;
  border-radius: 30px !important;
  text-align: center;
  color: #fff;
  padding-top: 5px;
  width: 90%;
  margin-left: 5%;
}
.title-model {
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #fff;
}

.dl-center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.dl-center div {
  padding: 2%;
}

.navbar-section {
  position: relative;
  z-index: 999;
}
.nav-bar-style img {
  max-height: 40px;
}

.nav {
  border-color: #0d0627;
}

.buttonText {
  text-align: center;
  font: normal normal normal 16px Lato;
  letter-spacing: 1px;
  color: #ffffff;
  font-size: 1.1rem;

  padding: 10px 5px;
  display: inline-block;
  position: relative;
}
.subscribeButton {
  text-align: center;
  font: normal normal normal 16px Lato;
  letter-spacing: 1px;
  color: #ffffff;
  box-shadow: 0px 0px 17px #0000006e;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 9px 18px !important;
}
.subscribeButton:hover {
  /* background: transparent linear-gradient(180deg, #ff1919 50%, #6012e8 100%); */
  background: #fff !important;
  border: 1px solid #ffffff;
  color: #4015d5 !important;
}
.buttonText:hover {
  /* background: transparent linear-gradient(180deg, #ff1919 50%, #6012e8 100%);  */
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.buttonText:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2.5px;
  left: 50%;
  position: absolute;
  /* background: transparent linear-gradient(90deg, #ff1919 0%, #6012e8 100%); */
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.buttonText:hover:after {
  width: 100%;
  left: 0;
}

.nav-bar-style .nav-link {
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff !important;
  padding: 0rem 1rem !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d0627 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff !important;
  text-decoration: none;
  background-color: #0d0627 !important;
}

.nav-bar-style .navbar {
  position: absolute !important;
  width: 100%;
  padding: 3rem 8rem;
}

.navbar-transparent .navbar {
  background: transparent !important;
}

.navbar-transparent .nav-bar-button button {
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  background: #0d0627;
  border: 0;
  padding: 0.375rem 1rem;
}

/* .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #fff!important;
  font-size: 19px;
} */
/* 
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  transition: -webkit-clip-path 275ms ease;
  transition: clip-path 275ms ease;
  transition: clip-path 275ms ease, -webkit-clip-path 275ms ease;
}

.navbar-dark .navbar-nav .nav-link:hover span::before,
.navbar-dark .navbar-nav .nav-link:focus span::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.navbar-dark .navbar-nav .nav-link span {
  position: relative;
  display: inline-block;
}

.navbar-dark .navbar-nav .nav-link span::before {
  position: absolute;
  content: attr(data-content);
  text-decoration-thickness: 3px;
  -webkit-text-decoration-color: #fff;
  text-decoration-color: #fff;
  -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  transition: -webkit-clip-path 275ms ease;
  transition: clip-path 275ms ease;
  transition: clip-path 275ms ease, -webkit-clip-path 275ms ease;
} */

.nav-bar-button svg {
  margin-right: 8px !important;
}

.navbar .navbar-collapse {
  padding-left: 1rem;
}

.nav-bar-button li {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

/* ************************ navbar element ************************* */
.ul-navbar {
  align-items: center;
}
.ul-navbar a {
  align-items: center;
  margin: 0px 10px;
}
.logo-text {
  text-align: left;
  font: 500 normal normal 17px/23px Peace Sans;
  letter-spacing: 1px;
  color: #ffffff;
  font-size: 17px;
  margin-left: 19px;
}

.nav-bar-style .link-subscribe a {
  box-shadow: 0px 0px 17px #0000006e;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 9px 18px !important;
}

/* *********************************************************************************************************** */
/* responsive */

@media only screen and (max-width: 991px) {
  .nav-bar-button li {
    display: none;
  }

  .navbar .navbar-collapse {
    padding-left: 0rem;
  }

  .navbar .navbar-collapse {
    background: transparent !important;
    z-index: 9999;
  }

  .navbar .navbar-collapse .nav-link {
    color: #ffffff !important;
  }
}

@media only screen and (max-width: 420px) {
  .navbar .navbar-collapse {
    background: transparent !important;
    z-index: 9999;
  }

  .nav-bar-style .navbar {
    padding: 1.25rem 2rem;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-brand {
    margin: 0 !important;
  }
  .navbar .navbar-collapse {
    background: linear-gradient(
        173deg,
        rgb(227, 37, 37) 0%,
        rgb(64, 21, 213) 100%
      )
      0% 0% no-repeat transparent !important;
    padding: 3% 0% 5% 0%;
    border-radius: 15px;
  }
  .navbar .navbar-collapse .ambassadorButton {
    margin-bottom: 10px;
  }
}
.ambassadorButton {
  background: #fff;
  color: #4015d5;
  text-align: center;
  font: normal normal normal 16px Lato;
  letter-spacing: 1px;
  box-shadow: 0px 0px 17px #0000006e;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 9px 18px !important;
}
.ambassadorButton:hover {
  background: transparent !important;
  color: #fff !important;
}
</style>
