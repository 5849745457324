<template lang="html">
  <div class="home" @click.right.prevent>
    <div class="home-container game-elements-page">
      <AmbassadorHeader />
      <UnderAmbassador />
    </div>
  </div>
</template>
<script>
import AmbassadorHeader from "../components/Ambassador/Ambassador.vue";
import UnderAmbassador from "../components/Ambassador/UnderAmbassador.vue";

export default {
  name: "Contact",
  components: {
    AmbassadorHeader,
    UnderAmbassador,
  },
  mounted() {
    this.$route.meta.title = "";
    this.$route.meta.description = " ";
    this.$route.meta.keywords = " ";
  },
  computed: {
    currentRouteName() {
      if (this.$route.name == "NotFound") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      dataToDisplay: {},
      items: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Contact",
          to: "/contact",
        },
      ],
    };
  },
};
</script>
<style lang="css">
.container-retro{
  max-width: 100% !important;
}
</style>
