<template lang="html">
  <div class="about" @click.right.prevent>
   
    </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AboutUs",
  data() {
      return {
        dataToDisplay:{},
         items : [
          {
        text:"Home",
        to:"/"
        },
        {
        text:"AboutUS",
        to:"/about-us"
        },
        ]
      }
      },
      components: {
    },
     created(){
        // axios
        // .get(
        //   "https://dracoss-backend.herokuapp.com/api/descriptions"
        // )
        // .then((response) => {
        //   console.log(response);
        //   this.dataToDisplay=response.data
        // })
        // .catch((error) => {
        //   this.errorMessage = error.message;
        // });
    },
    mounted(){
   this.$route.meta.title = "About Us"
   this.$route.meta.description = ""
   this.$route.meta.keywords = ""
   

},
    methods: {
        // getImage(item){
        //   if(item){
        //     return "https://res.cloudinary.com/dfsd4vgsl/image/upload/v1648146968/"+item
        //   }else{
        //     return "https://res.cloudinary.com/dfsd4vgsl/image/upload/v1614448392/495-4952535_create-digital-profile-icon-blue-user-profile-icon_2_cptuoe.png"
        //   }
        // }
    },
};

</script>
<style lang="css">

</style>

