import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLightTheme: false
  },
  mutations: {
    dark(state) {
      state.isLightTheme = false
    },
    light(state) {
      state.isLightTheme = true
    }
  },
  actions: {
  },
  modules: {
  }
});
