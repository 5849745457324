<template>
  <div class="mainDiv">
    <Wave v-if="!this.$store.state.isLightTheme" />
    <WaveLight v-if="this.$store.state.isLightTheme"></WaveLight>
    <div
      class="background"
      :class="{ lightBackground: this.$store.state.isLightTheme }"
    >
      <div class="circles">
        <figure
          class="circle"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle1"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle2"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
      </div>

      <b-container>
        <b-row class="row-img-text">
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style"
                data-aos="slide-right"
                data-aos-once="true"
                src="../../assets/gif/Assetstore.gif"
                data-aos-duration="1250"
              />
              </div
          ></b-col>
          <b-col cols="12" lg="6" md="6">
            <div
              class="textContainer"
              data-aos="slide-left"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                RETRO RESOURCE CENTER
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                With the Retro Resource Center, users will be able to create,
                buy and sell retro assets on this marketplace specially designed
                for the retro community. This is a great opportunity for anyone
                who loves retro style and nostalgia to express their creativity
                and passion. Whether you are a game developer, a graphic
                designer, or a hobbyist, you will find something that suits your
                needs and preferences on the Retro Resource Center.
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row class="row-img-text">
          <b-col cols="12" lg="6" md="6" >
            <div
              class="textContainer"
              data-aos="slide-right"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                GAME BUILDER
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                Get ready to unleash your retro creativity with the RetroVerse
                Game Builder! This amazing tool lets you make games and
                experiences for everyone, using the style and charm of your
                favorite retro consoles. Whether you want to recreate the pixel
                art of the NES, the colorful sprites of the SNES, or the 3D
                graphics of the PlayStation, we have you covered! The RetroVerse
                Game Builder will automatically adjust your game to fit the
                style of your choice and more!
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style img-game-builder"
                data-aos="slide-left"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/gamebuilder.gif"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="row-img-text">
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style"
                data-aos="slide-right"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/landcustomization.gif"
              />
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <div
              class="textContainer"
              data-aos="slide-left"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                CUSTOMIZE YOUR LAND
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                Are you ready to dazzle the world with your project? Make your
                land unique and eye-catching so that everyone will want to play
                your game. You can also share the instruction manual, offer
                in-game items and DLCs, and even sell physical goodies and much more.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Wave from "../../assets/svg/wave.svg";
import WaveLight from "../../assets/svg/waveLight.svg";
import Axe from "../../assets/svg/axe.svg";
import Coin from "../../assets/svg/coin.svg";
import Tree from "../../assets/svg/tree.svg";
import Balloon from "../../assets/svg/balloon.svg";
import WhiteLogo from "../../assets/svg/Logo.svg";
import LogoLight from "../../assets/svg/logoLight.svg";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import Card from "../Card/Card.vue";

export default {
  name: "UnderHeroSection",
  components: {
    Wave,
    WhiteLogo,
    PrimaryButton,
    Card,
    Tree,
    Axe,
    Balloon,
    Coin,
    WaveLight,
    LogoLight,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    subscribe() {},
  },
};
</script>
<style lang="css">
.mainDiv {
  margin-top: -16%;
  position: relative;
}
.row-container-assets {
  display: flex;
  flex-direction: row;
  margin: 8% 0px;
  width: 1100px;
  justify-content: space-between;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}


.AssetStore {
  background: transparent linear-gradient(181deg, #ff1919 0%, #6012e8 100%);
  font-family: Peace Sans;
  font-size: 43px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.second {
  width: 100%;
}

.PrimaryButton {
  margin: 0 !important;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.titles {
  display: flex;
  justify-content: space-around;
  margin: 56px 0 0 -60px;
}

.titleLight {
  color: #000748 !important;
}

.title {
  text-align: left;
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.circles {
  position: absolute;
}

.video {
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  object-fit: none;
}

.circleLight {
  background: transparent linear-gradient(180deg, #e697ff 0%, #d9ddff00 100%) 0%
    0% !important;
  opacity: 0.15 !important;
}

.circle {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%);
  opacity: 0.5;
  border-radius: 50%;
  top: 620px;
  left: -173.95562744140625px;
  width: 800px;
  height: 500px;
}

.circle1 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #06009f 100%);
  opacity: 0.4;
  right: 142px;
  position: absolute;
  width: 486px;
  height: 430px;
  position: absolute;
  border-radius: 500%;
}

.circle3 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #060060 100%);
  opacity: 0.4;
  filter: blur(10px);
  right: 50px;
  position: absolute;
  bottom: -24px;
  width: 23%;
  height: 20%;
  border-radius: 500%;
}

.RETROVERSE {
  font-size: 60px;
  background: transparent linear-gradient(184deg, #ff1919 0%, #6012e8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 80%;
  letter-spacing: 0.2rem;
  font-family: Peace Sans;
  text-align: center;
}

.circle2 {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.35;
  border-radius: 50%;
  top: 930px;
  left: 674px;
  width: 1121px;
  height: 1121px;
  right: 50px;
}



.lightBackground {
  background-color: #fff !important;
}

.background {
  background-color: #000c4a;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -1%;
  flex-direction: column;
}

.img-game-builder{
  width: 90%!important;
  margin-left: 15%!important;
}
</style>
