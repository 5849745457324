<template>
  <!-- Footer -->
  <footer
    class="footer text-white"
    :class="{ footerLight: this.$store.state.isLightTheme }"
  >
    <div class="footerContainer">
      <span
        class="letsPlay"
        :class="{ letsPlayLight: this.$store.state.isLightTheme }"
        >THE NEW ERA OF RETRO</span
      >

      <div class="">
        <vueper-slides
          class="no-shadow"
          :visible-slides="3"
          :slide-ratio="1 / 3"
          :dragging-distance="70"
          :bullets="false"
          :touchable="false"
          :transition-speed="1000"
          :breakpoints="{
            1200: { visibleSlides: 2, slideMultiple: 2 },
            767: { visibleSlides: 1, slideMultiple: 1 },
          }"
          :autoplay="true"
        >
          <vueper-slide v-for="(item, i) in imgs" :key="i">
            <template #content>
              <div class="img-carousel">
                <img :src="item" class="hover-rotate" />
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <!-- <span
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1250"
        class="footerText font-style-retro-medium objectif-footer display-non-element"
        :class="{ footerTextLight: this.$store.state.isLightTheme }"
      >
        Our goal is to make Retro shine like never before, and to support the
        amazing retro developers and the passionate retro community that are
        spread all over the internet. <br />We want to create a new wave of
        Retro excitement, full of innovations and possibilities.<br />
        Let's build the new era of Retro together !</span
      > -->
      <span
        class="footerText font-style-retro-medium-responsive objectif-footer display-element-responsive"
        :class="{ footerTextLight: this.$store.state.isLightTheme }"
      >
        Our goal is to make Retro shine like never before, and to support the
        amazing retro developers and the passionate retro community that are
        spread all over the internet. <br />We want to create a new wave of
        Retro excitement, full of innovations and possibilities.<br />
        Let's build the new era of Retro together !</span
      >
      <div class="newsletters-section display-non-element">
        <div class="skin-subscribe">
          <img src="../assets/img/footer-skin.png" />
        </div>

        <span
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="1250"
          class="footerText-subscribe font-style-retro-medium d-flex-retro"
        >
          Subscribe to our newsletter to get our latest update and an exclusive
          item for free !</span
        >

        <div
          id="contact"
          data-aos="zoom-in"
          data-aos-once="true"
          class="inptButton subscribe-now-section"
          data-aos-duration="1250"
        >
          <b-form-input
            id="name"
            v-model="form.email"
            class="input-footer-subscribe subscribe-input"
            :class="{ inputLight: this.$store.state.isLightTheme }"
            required
            placeholder="Enter your Email"
          ></b-form-input>
          <!-- <PrimaryButton
            type="third"
            :label="'Subscribe Now'"
            :onclick="sendEmail()"
          /> -->
          <button @click="sendEmail()" class="btn-subscribe-footer">
            Subscribe
          </button>
        </div>
      </div>
      <div id="contact" class="inptButton display-element-responsive">
        <b-form-input
          id="name"
          v-model="form.email"
          class="input subscribe-input"
          :class="{ inputLight: this.$store.state.isLightTheme }"
          required
          placeholder="Enter your Email"
        ></b-form-input>

        <button @click="sendEmail()" class="btn-subscribe-footer-responsive">
          Subscribe
        </button>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        class="socialMedia display-non-element"
        data-aos-duration="1250"
      >
        <BasicIcon :onClick="linkSocialDiscord">
          <Discord v-if="!this.$store.state.isLightTheme" />
          <DiscordLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialFacebook">
          <Facebook v-if="!this.$store.state.isLightTheme" />
          <FacebookLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialInstagram">
          <Instagram v-if="!this.$store.state.isLightTheme" />
          <InstagramLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialTwitter">
          <Twitter v-if="!this.$store.state.isLightTheme" />
          <TwitterLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialYoutube">
          <Youtube v-if="!this.$store.state.isLightTheme" />
          <YoutubeLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <div class="WhiteLogo">
          <LightLogo v-if="this.$store.state.isLightTheme" />
          <WhiteLogo v-if="!this.$store.state.isLightTheme" />
        </div>
      </div>

      <div class="socialMedia display-element-responsive">
        <BasicIcon :onClick="linkSocialDiscord">
          <Discord v-if="!this.$store.state.isLightTheme" />
          <DiscordLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialFacebook">
          <Facebook v-if="!this.$store.state.isLightTheme" />
          <FacebookLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialInstagram">
          <Instagram v-if="!this.$store.state.isLightTheme" />
          <InstagramLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialTwitter">
          <Twitter v-if="!this.$store.state.isLightTheme" />
          <TwitterLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
        <BasicIcon :onClick="linkSocialYoutube">
          <Youtube v-if="!this.$store.state.isLightTheme" />
          <YoutubeLight v-if="this.$store.state.isLightTheme" />
        </BasicIcon>
      </div>

      <div
        data-aos="zoom-in"
        data-aos-once="true"
        class="terms display-non-element"
        data-aos-duration="1250"
      >
        <router-link to="/general-condition"
          ><span
            class="term"
            :class="{ termLight: this.$store.state.isLightTheme }"
            >Privacy Policy</span
          ></router-link
        >
        <div
          class="seperator"
          :class="{ seperatorLight: this.$store.state.isLightTheme }"
        />
        <router-link to="/general-condition">
          <span
            class="term"
            :class="{ termLight: this.$store.state.isLightTheme }"
            >Terms of Use</span
          ></router-link
        >
        <div
          class="seperator"
          :class="{ seperatorLight: this.$store.state.isLightTheme }"
        />
        <router-link to="/general-condition">
          <span
            class="term"
            :class="{ termLight: this.$store.state.isLightTheme }"
            >Cookie Preferences</span
          ></router-link
        >
        <div
          class="seperator"
          :class="{ seperatorLight: this.$store.state.isLightTheme }"
        />
        <router-link to="/contact">
          <span
            class="term"
            :class="{ termLight: this.$store.state.isLightTheme }"
            >Contact</span
          ></router-link
        >
      </div>

      <div class="terms display-element-responsive">
        <div class="footer-link-responsive">
          <a href="/general-condition"
            ><span
              class="term"
              :class="{ termLight: this.$store.state.isLightTheme }"
              >Privacy Policy</span
            ></a
          >
          <a href="/general-condition">
            <span
              class="term"
              :class="{ termLight: this.$store.state.isLightTheme }"
              >Terms of Use</span
            ></a
          >
          <a href="/general-condition#cookies">
            <span
              class="term"
              :class="{ termLight: this.$store.state.isLightTheme }"
              >Cookie Preferences</span
            ></a
          >
          <router-link to="/contact">
            <span
              class="term"
              :class="{ termLight: this.$store.state.isLightTheme }"
              >Contact</span
            ></router-link
          >
        </div>
      </div>
      <!-- <div
        data-aos="zoom-in"
        data-aos-once="true"
        class="terms links-footer"
        data-aos-duration="1250"
      >
        <b-container>
          <b-row class="">
            <b-col cols="12" lg="3" md="3">
              <div class="d-flex-retro footer-useful-link">
                <router-link to="/general-condition"
                  ><span
                    class="term"
                    :class="{ termLight: this.$store.state.isLightTheme }"
                    >Privacy Policy</span
                  ></router-link
                >
                <div
                  class="seperator display-non-element"
                  :class="{ seperatorLight: this.$store.state.isLightTheme }"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="3" md="3">
              <div class="d-flex-retro footer-useful-link">
                <router-link to="/general-condition">
                  <span
                    class="term"
                    :class="{ termLight: this.$store.state.isLightTheme }"
                    >Terms of Use</span
                  ></router-link
                >
                <div
                  class="seperator display-non-element"
                  :class="{ seperatorLight: this.$store.state.isLightTheme }"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="3" md="3">
              <div class="d-flex-retro footer-useful-link">
                <router-link to="/general-condition">
                  <span
                    class="term"
                    :class="{ termLight: this.$store.state.isLightTheme }"
                    >Cookie Preferences</span
                  ></router-link
                >
                <div
                  class="seperator display-non-element"
                  :class="{ seperatorLight: this.$store.state.isLightTheme }"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="3" md="3">
              <div class="d-flex-retro footer-useful-link">
                <router-link to="/contact">
                  <span
                    class="term"
                    :class="{ termLight: this.$store.state.isLightTheme }"
                    >Contact</span
                  ></router-link
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div> -->
      <!-- data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1250" -->
      <span
        class="credits"
        :class="{ creditsLight: this.$store.state.isLightTheme }"
        >Game trademarks and copyrights are properties of their respective
        owners. RetroVerse properties are trademarks of RetroVerse. ©
        {{ getDynamicYear() }} RetroVerse.</span
      >
    </div>
  </footer>
  <!-- Footer -->
</template>
<script>
import axios from "axios";
// import PrimaryButton from "../components/Buttons/PrimaryButton.vue";
import BasicIcon from "../components/Icon.vue";
import Facebook from "../assets/svg/facebook.svg";
import FacebookLight from "../assets/svg/facebookLight.svg";
import Instagram from "../assets/svg/instagram.svg";
import InstagramLight from "../assets/svg/instagramLight.svg";
import Twitter from "../assets/svg/twitter.svg";
import TwitterLight from "../assets/svg/twitterLight.svg";
import Youtube from "../assets/svg/youtube.svg";
import YoutubeLight from "../assets/svg/youtubeLight.svg";
import WhiteLogo from "../assets/svg/WhiteLogo.svg";
import LightLogo from "../assets/svg/LightLogo.svg";
import Discord from "../assets/svg/discord.svg";
import DiscordLight from "../assets/svg/discordLight.svg";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {
    VueperSlides,
    VueperSlide,
    // PrimaryButton,
    InstagramLight,
    TwitterLight,
    YoutubeLight,
    BasicIcon,
    Facebook,
    Instagram,
    Twitter,
    Youtube,
    WhiteLogo,
    FacebookLight,
    LightLogo,
    Discord,
    DiscordLight,
  },
  data() {
    return {
      imgs: [
        // "https://retroverse.game/img-web/carousel-retro0.jpg",
        "http://retroverse.game/img-web/carousel-retro1.jpg",
        "http://retroverse.game/img-web/carousel-retro2.jpg",
        "http://retroverse.game/img-web/carousel-retro3.jpg",
        "http://retroverse.game/img-web/carousel-retro4.png",
        "http://retroverse.game/img-web/carousel-retro5.png",
      ],
      form: {
        email: "",
      },
      show: true,
      Home: true,
    };
  },

  methods: {
    linkSocialDiscord() {
      window.open("https://discord.gg/HvCHwyNrzn", "_blank");
    },
    linkSocialFacebook() {
      window.open("https://www.facebook.com/RetroVerse.game", "_blank");
    },
    linkSocialInstagram() {
      window.open("https://instagram.com/retroverse.game", "_blank");
    },
    linkSocialTwitter() {
      window.open("https://twitter.com/RetroVerse_Game", "_blank");
    },
    linkSocialYoutube() {
      window.open("https://www.youtube.com/@RetroVerseGame", "_blank");
    },
    getDynamicYear() {
      return new Date().getFullYear(); // returns the current year
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    sendEmail() {
      axios
        .post("https://retroverse-ocyt.onrender.com/api/subscribes", {
          email: this.form.email,
        })
        .then((response) => {
          console.log(response);
          this.form.email = "";
          this.$toast.open({
            message: "Email sent",
            type: "success",
            duration: 7000,
          });
        })
        .catch((error) => {
          this.form.email = "";
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Email exist",
            type: "error",
            duration: 7000,
          });
        });
    },
    // sendMessage() {
    //   axios
    //     .post("https://dracoss-backend.herokuapp.com/api/contacts", {
    //       email: this.form,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.form.email = "";
    //       this.form.name = "";
    //       this.form.message = "";
    //       this.$toast.open({
    //         message: "Message sent",
    //         type: "success",
    //         duration: 7000,
    //       });
    //     })
    //     .catch((error) => {
    //       this.errorMessage = error.message;
    //       this.$toast.open({
    //         message: "Error during sending",
    //         type: "error",
    //         duration: 7000,
    //       });
    //     });
    // },
  },
  mounted() {
    this.Home = this.$route.meta.title == "Home";
  },
};
</script>
<style lang="css">
/* .hover-rotate-left:hover {
  transform: scale(1.05) rotate(-5deg)!important;
} */
.hover-rotate:hover {
  transform: scale(1.05) !important;
}
/* .hover-rotate-right:hover {
  transform: scale(1.05) rotate(5deg)!important;
} */
.footerLight {
  background: #fff !important;
}

.footer {
  z-index: 10;
  position: relative;
  width: 100% !important;
  background: transparent
    linear-gradient(164deg, #bc00bc 0%, #060060 50%, #060060 100%);
}

.seperatorLight {
  background-color: #000748 !important;
}

.seperator {
  height: 17px;
  width: 1px;
  background-color: #fff;
}
.inputLight {
  background: #e9e9e9 0% 0% no-repeat padding-box !important;
}
.inputLight::placeholder {
  color: #ff53bb !important;
}

::placeholder {
  color: #fff !important   ;
}
.WhiteLogo {
  margin-left: 40px;
}

.creditsLight {
  color: #000748 !important;
}

.credits {
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 49px;
  font-weight: 400;
}

.termLight {
  color: #000748 !important;
}

.term {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 22px;
}

.terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 39px;
}

.inptButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.socialMedia {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 45px;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.container-retro {
  max-width: 100% !important;
}

.footerTextLight {
  color: #000748 !important;
}

.letsPlayLight {
  color: #000748 !important;
}

.letsPlay {
  text-align: left;
  font-size: 36px;
  font-family: Peace Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding-top: 70px;
}

.border-divider {
  border-bottom: 0.075rem solid #e9e9e9 !important;
}

.p-white-footer {
  font-size: 1.1rem;
  color: #fff;
}

.title {
  font-size: 50px;
  font-family: Roboto;
  font-weight: 500;
}

.images {
  display: flex;
  flex-direction: row;
  padding: 50px 140px;
  justify-content: space-between;
  align-items: center;
}

.edges {
  width: 318px;
  height: 211px;
  border-radius: 25px;
}

.center {
  height: 255px;
  width: 386px;
  border-radius: 25px;
  margin: 0 22px;
}

.social-media {
  padding: 0.375rem 0.85rem;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 50% !important;
  background-color: #1979b3 !important;
  border-color: #1979b3 !important;
  /* width: 38px; 
  height: 38px; */
  padding-bottom: 10px !important;
  /* bottom: 10px !important; */
  /*justify-content: center; */
  /* position: absolute; */
}

.vertical-line {
  border-left: 3px solid #fff;
  display: inline-block;
  height: 300px;
}

.form-btn {
  background-color: transparent !important;
  border: solid 2px white !important;
}
.footer .inptButton .subscribe-input {
  padding: 26.5px 50.5px 26.5px 20px !important;
  margin-top: 0.5% !important;
  /* border: none !important; */
}
.input-footer-subscribe {
  background: transparent linear-gradient(145deg, #ff53bb 0%, #9d6dff 100%);
  box-shadow: 0px 0px 19px #000000;
  border-radius: 30px !important;
  opacity: 0.4;
  width: 45% !important;
  color: white !important;
  visibility: initial !important;
  border: 1px solid #9d6dff !important;
}
.footer .inptButton .subscribe-input:focus {
  color: #fff !important;
  opacity: 0.7;
}
.btn-to-top button {
  position: absolute;
  top: 38px;
  right: -140px;
  border-radius: 50% !important;
  background-color: #1979b3 !important;
  border: 1px solid transparent;
  padding: 0.45rem 0.75rem;
  font-size: 0.9rem;
}

@media only screen and (max-width: 445px) {
  .btn-to-top {
    display: none !important;
  }
}

@media all and (max-device-width: 640px) {
  .vertical-line {
    display: none;
  }
}
.vueperslides {
  width: 100vw;
}
.vueperslides__inner {
  width: 63%;
  margin-left: 18.5%;
}
.vueperslide,
.vueperslide__image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vueperslide--active img {
  height: 255px;
  width: 386px;
}
.footerLight .vueperslides__arrow svg {
  color: #4015d5;
}
@media all and (max-device-width: 1600px) {
  .vueperslides__inner {
    width: 75%;
    margin-left: 12.5%;
  }
}
/* .links-footer{
  width:100%;
} */
/* .footer-useful-link span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 20%;
} */
.footerText-subscribe {
  padding: 20px 150px 30px 150px;
  text-align: center;
  letter-spacing: 0px;
}

.newsletters-section {
  background: transparent linear-gradient(302deg, #bc00bc 0%, #060060 100%);
  /* background:#bc00bc ; */
  border-radius: 50px;
  width: 63%;
  right: 0px;
  bottom: 0px;
  margin-bottom: 70px;
  padding: 45px 90px 55px 90px;
  position: relative;
}
.newsletters-section img {
  position: absolute;
  top: -3px!important;
  right: -54px!important;
  width: 12%!important;
  z-index: 10;
}
.objectif-footer {
  margin-bottom: 8%;
  width: 90%;
}
.subscribe-now-section {
  width: 50% !important;
  margin-top: 1.5%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 25%;
}
.btn-subscribe-footer {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0%
    0% no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  border-width: 0px;
  padding: 19px 28.5px;
  margin: 0px 10px 0px 0px;
  /* width: 200px; */
  color: #fff;
  font: normal normal normal 16px Lato;
  font-weight: 600;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-subscribe-footer-responsive {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0%
    0% no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  border-width: 0px;
  padding: 19px 28.5px;
  margin: 0px 10px 0px 0px;
  /* width: 200px; */
  color: #fff;
  font: normal normal normal 16px Lato;
  font-weight: 600;
  width: 100%;
  margin-top: 3%;
}
.btn-subscribe-footer:hover {
  background-position: 100% 100%;
}
.skin-subscribe {
  position: relative;
}
</style>
