<template>
  <div class="mainDiv">
    <Wave v-if="!this.$store.state.isLightTheme" />
    <WaveLight v-if="this.$store.state.isLightTheme"></WaveLight>
    <div
      class="background"
      :class="{ lightBackground: this.$store.state.isLightTheme }"
    >
      <div class="circles">
        <figure
          class="circle"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle1"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle2"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
      </div>
      <b-container>
        <b-row class="row-img-play-text">
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style"
                data-aos="slide-right"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/plaza-diorama.gif"
              /></div
          ></b-col>
          <b-col cols="12" lg="6" md="6">
            <div
              class="textContainer"
              data-aos="slide-left"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                EXPLORE NEW GAMES
                <!-- LIKE NEVER BEFORE -->
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                As you journey through the RETROVERSE Hub, you'll find amazing
                games waiting for you. You walk through lands and notice a game
                that sparks your curiosity ? Smash the block and dive in !
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row class="row-img-play-text">
          <b-col cols="12" lg="6" md="6">
            <div
              class="textContainer"
              data-aos="slide-right"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                CUSTOMIZE YOUR AVATAR
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                Share your Retro passion with the community by making your
                Avatar awesome! No matter if you're into RPGs or platformers,
                we've got something for everyone! Show off your style and
                personality or experiment with something new and original!
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style avatar-gif"
                data-aos="slide-left"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/WebSite_CharaCustm.gif"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="row-img-play-text leaderboard-gif">
          <b-col cols="12" lg="6" md="6" class="display-non-element">
            <div>
              <img
                class="gif-style"
                data-aos="slide-right"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/leaderBoard.gif"
              />
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <div
              class="textContainer"
              data-aos="slide-left"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title font-style-retro-title-large"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                SPECIAL EVENTS
              </h5>
              <p
                class="detaiLText text-justify font-style-retro-medium"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                On RetroVerse, you can have fun and win prizes with special
                events such as tournaments, speedruns, treasure hunts, art
                contests and so much more! There will be also themed events and
                awesome partnerships to look forward to!
              </p>
            </div>
          </b-col>
        </b-row>

        <!--  <b-row class="row-img-play-text">
          <b-col cols="12" lg="6" md="6">
            <div
                   class="textContainer"
              data-aos="slide-right"
              data-aos-once="true"
              data-aos-duration="1250"
            >
              <h5
                class="title"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                RETROVERSE PLAZA
              </h5>
              <p
                class="detaiLText text-justify"
                :class="{ titleLight: this.$store.state.isLightTheme }"
              >
                RetroVerse mall is a social hub where avatars spawn and can be
                in touch with news about new release for games, items and maybe
                even partnerships ?
              </p>
            </div>
          </b-col>
    <b-col cols="12" lg="6" md="6" class="display-non-element">            
      <div>
              <img
                class="gif-style"
           data-aos="slide-left"
                data-aos-once="true"
                data-aos-duration="1250"
                src="../../assets/gif/play2.gif"
              />
            </div>
          </b-col> 
        </b-row>-->
      </b-container>
      <!-- <div class="videoContainer">
        <div class="row-container-player">
          <img
            data-aos="slide-right"
            data-aos-once="true"
            data-aos-duration="1250" 
            src="../../assets/img/img1.png"
            class="imgClass"
          />
          <div class="textContainer"  
          data-aos="slide-left"
            data-aos-once="true"
            data-aos-duration="1250" >
            <h5 class="title" :class="{ titleLight: this.$store.state.isLightTheme }">
              A NEW WAY TO DISCOVER GAMES
            </h5>
            <p class="detaiLText" :class="{ titleLight: this.$store.state.isLightTheme }">
              Discover new games as you travel inside the RETROVERSE Hub. A Land has drawn
              your attention ? Hit the block to enter the game !
            </p>
          </div>
        </div>
        <div class="row-container-player">
          <div class="textContainer"   data-aos="slide-right"
            data-aos-once="true"
            data-aos-duration="1250" >
            <h5 class="title" :class="{ titleLight: this.$store.state.isLightTheme }">
              RETROVERSE PLAZA
            </h5>
            <p class="detaiLText" :class="{ titleLight: this.$store.state.isLightTheme }">
              Retroverse mall is a social hub where avatars spawn and can be in touch with
              news about new release for games, items and maybe even partnerships ?
            </p>
          </div>
          <img
          data-aos-duration="1250"
            data-aos="slide-left"
            data-aos-once="true"
             src="../../assets/img/img1.png"
            class="imgClass"
          />
        </div>
        <div class="row-container-player mb-200">
          <img
          data-aos-duration="1250"
            data-aos="slide-right"
            data-aos-once="true"
             src="../../assets/img/img1.png"
            class="imgClass"
          />
          <div class="textContainer"   data-aos="slide-left"
            data-aos-once="true"
            data-aos-duration="1250" >
            <h5 class="title" :class="{ titleLight: this.$store.state.isLightTheme }">
              TREASURE HUNT
            </h5>
            <p class="detaiLText" :class="{ titleLight: this.$store.state.isLightTheme }">
              Sometimes, a special event will be held to find a treasure hidden in the
              lands.
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Wave from "../../assets/svg/wave.svg";
import WaveLight from "../../assets/svg/waveLight.svg";
import Axe from "../../assets/svg/axe.svg";
import Coin from "../../assets/svg/coin.svg";
import Tree from "../../assets/svg/tree.svg";
import Balloon from "../../assets/svg/balloon.svg";
import WhiteLogo from "../../assets/svg/Logo.svg";
import LogoLight from "../../assets/svg/logoLight.svg";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import Card from "../Card/Card.vue";

export default {
  name: "UnderHeroSection",
  components: {
    Wave,
    WhiteLogo,
    PrimaryButton,
    Card,
    Tree,
    Axe,
    Balloon,
    Coin,
    WaveLight,
    LogoLight,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    subscribe() {},
  },
};
</script>
<style lang="css">
.mainDiv {
  margin-top: -16%;
  position: relative;
}
.row-container-player {
  display: flex;
  flex-direction: row;
  margin: 8% 0px;
  width: 1100px;
  justify-content: space-between;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.playerGlobal {
  display: contents;
}

.player {
  vertical-align: middle;
  border-style: none;
  position: absolute;
  right: 8%;
  bottom: 0;
  width: 12%;
  height: auto;
}

.AssetStore {
  background: transparent linear-gradient(181deg, #ff1919 0%, #6012e8 100%);
  font-family: Peace Sans;
  font-size: 43px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.second {
  width: 100%;
}

.PrimaryButton {
  margin: 0 !important;
}

.underVideoSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 56px;
  padding: 0 21%;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.titles {
  display: flex;
  justify-content: space-around;
  margin: 56px 0 0 -60px;
}

.titleLight {
  color: #000748 !important;
}

.title {
  text-align: left;
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.circles {
  position: absolute;
}

.video {
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  object-fit: none;
}

.circleLight {
  background: transparent linear-gradient(180deg, #e697ff 0%, #d9ddff00 100%) 0%
    0% !important;
  opacity: 0.15 !important;
}

.circle {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%);
  opacity: 0.5;
  border-radius: 50%;
  top: 620px;
  left: -173.95562744140625px;
  width: 800px;
  height: 500px;
}

.circle1 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #06009f 100%);
  opacity: 0.4;
  right: 142px;
  position: absolute;
  width: 486px;
  height: 430px;
  position: absolute;
  border-radius: 500%;
}

.circle3 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #060060 100%);
  opacity: 0.4;
  filter: blur(10px);
  right: 50px;
  position: absolute;
  bottom: -24px;
  width: 23%;
  height: 20%;
  border-radius: 500%;
}

.RETROVERSE {
  font-size: 60px;
  background: transparent linear-gradient(184deg, #ff1919 0%, #6012e8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 80%;
  letter-spacing: 0.2rem;
  font-family: Peace Sans;
  text-align: center;
}

.circle2 {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.35;
  border-radius: 50%;
  top: 930px;
  left: 674px;
  width: 1121px;
  height: 1121px;
  right: 50px;
}

.lightBackground {
  background-color: #fff !important;
}

.background {
  background-color: #000c4a;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -1%;
  flex-direction: column;
}

.avatar-gif {
  width: 90% !important;
  margin-left: 10%;
  margin-bottom: 20%;
}
.leaderboard-gif {
  margin-bottom: 10% !important;
}
</style>
