<template>
  <div id="app">
    <router-view />
    <div class="toggle">
      <input type="checkbox" id="myCheckbox" v-model="checked" />
      <label for="myCheckbox">
        <Sun class="sun" />
        <Moon class="moon" />
      </label>
    </div>
  </div>
</template>
<script>
import Sun from "./assets/svg/sun.svg";
import Moon from "./assets/svg/moon.svg";
export default {
  components: { Sun, Moon },
  data() {
    return {
      activeItem: 0,
      checked: false,
    };
  },

  watch: {
    checked: {
      handler() {
        this.$store.commit(this.$store.state.isLightTheme ? "dark" : "light");
      },
      // immediate: true,
    },
  },
};
</script>
<style lang="css">
@font-face {
  font-family: "Peace Sans";
  src: local("Peace Sans"), url(./assets/font/Peace\ Sans\ Webfont.ttf) format("truetype");
}

label {
  width: 55px;
  height: 25px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

label:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 3px;
  left: 2px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
}

input {
  width: 0;
  height: 0;
  visibility: hidden;
}

input:checked + label {
  background: #242424;
}

input:checked + label:after {
  left: 38px;
  transform: translateX(-30%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

label,
label:after {
  transition: 0.3s;
}

label:active:after {
  width: 20px;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./assets/font/Lato-Black.ttf) format("truetype");
}

.toggle {
  z-index: 9999;
  position: fixed;
  top: 50%;
  right: 3%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

#app {
  font-family: "Roboto", sans-serif;
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
}

/* * {
  margin: 0 !important;
}*/
body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label svg {
  position: absolute;
  width: 17px;
  z-index: 100;
}

label svg.sun {
  left: 3px;
  fill: #fff;
  transition: 0.3s;
}

label svg.moon {
  left: 33px;
  fill: #7e7e7e;
  transition: 0.3s;
}

input:checked + label svg.sun {
  fill: #7e7e7e;
}

input:checked + label svg.moon {
  fill: #fff;
}

html {
  scroll-behavior: smooth;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
}
</style>
