<template>
  <div class="mainDiv contact">
    <Wave v-if="!this.$store.state.isLightTheme" />
    <WaveLight v-if="this.$store.state.isLightTheme"></WaveLight>
    <div
      class="background"
      :class="{ lightBackground: this.$store.state.isLightTheme }"
    >
      <div class="circles">
        <figure
          class="circle"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle1"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle2"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
      </div>
      <div class="videoContainer">
        <div class="row-container-contact-text font-style-retro-medium">
          <p :class="{ titleLight: this.$store.state.isLightTheme }">
            Our objective is to develop a frontline ambassador network to
            promote the vision of RetroVerse by showcasing the benefits of a
            platform designed specifically for Retro.<br> <br> Bringing together the
            global Retro community is our main short-term goal, but attracting a
            wide audience of gamers and popularizing Retro remains an
            equally important objective.<br> <br>  That's why we are launching our
            official ambassador program to help grow the RetroVerse community.
          </p>
        </div>
        <div class="row-container-contact row">
          <b-col cols="12" lg="12" md="12" class="display-non-element">
            <div class="d-flex-retro mb-75">
              <img
                class="gif-style cart-ambassador"
                src="../../assets/img/ambassador.png"
              /></div
          ></b-col>
          <div class="col-md-6">
            <b-form-input
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              required
              placeholder="Enter your Name or Nickname"
              v-model="dataToSend.name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <b-form-input
              class="input-contact contact-right-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              required
              type="email"
              placeholder="Enter your Email *"
              v-model="dataToSend.email"
            ></b-form-input>
          </div>
        </div>
     

        <!-- <b-form-input
            class="input-contact contact-left-input"
            :class="{ inputLight: this.$store.state.isLightTheme }"
            required
            placeholder="Why do you think you would be a good Ambassador? "
          ></b-form-input> -->

        <div class="row-container-contact row">
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.topic"
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              required
              placeholder="Which subjects within the retro theme interest you?"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.language"
              class="input-contact contact-right-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Which is your main Language?"
            ></b-form-input>
          </div>
        </div>

        <div class="row-container-contact row">
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.twitterLink"
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Twitter Link "
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.youtubeLink"
              class="input-contact contact-right-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Youtube link"
            ></b-form-input>
          </div>
        </div>
        <div class="row-container-contact row">
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.liveStreamingLink"
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Live Streaming link"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.facebookLink"
              class="input-contact contact-right-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Facebook link"
            ></b-form-input>
          </div>
        </div>
        <div class="row-container-contact row">
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.discordLink"
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Discord channel link"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <b-form-input
              v-model="dataToSend.releventSocialPlatform"
              class="input-contact contact-right-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="Any other Relevant Social Platform "
            ></b-form-input>
          </div>
        </div>
           <div class="row-container-contact row select-input">
          <div class="col-md-12">
            <b-form-textarea
              v-model="dataToSend.reason"
              rows="5"
              class="input-contact contact-left-input"
              :class="{ inputLight: this.$store.state.isLightTheme }"
              placeholder="What is your area of expertise and why do you think you would be a good Ambassador?"
            ></b-form-textarea>
          </div>
        </div>
        <div class="row-container-contact-btn row">
          <div class="col-md-12">
            <!-- <PrimaryButton type="third" :label="'Send'" class="float-right" /> -->
            <button @click="sendContact()" class="btn-ambassador-page">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Wave from "../../assets/svg/wave.svg";
import WaveLight from "../../assets/svg/waveLight.svg";
import Axe from "../../assets/svg/axe.svg";
import Coin from "../../assets/svg/coin.svg";
import Tree from "../../assets/svg/tree.svg";
import Balloon from "../../assets/svg/balloon.svg";
import WhiteLogo from "../../assets/svg/Logo.svg";
import LogoLight from "../../assets/svg/logoLight.svg";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import Card from "../Card/Card.vue";

export default {
  name: "undeContact",
  components: {
    Wave,
    WhiteLogo,
    PrimaryButton,
    Card,
    Tree,
    Axe,
    Balloon,
    Coin,
    WaveLight,
    LogoLight,
  },
  data() {
    return {
      selected: null,
      dataToSend: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    sendContact() {
      axios
        .post(
          "https://retroverse-ocyt.onrender.com/api/ambassadors",
          this.dataToSend
        )
        .then((response) => {
          console.log(response);
          this.dataToSend = {};
          this.$toast.open({
            message: "Message sent",
            type: "success",
            duration: 7000,
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.$toast.open({
            message: "Error during sending",
            type: "error",
            duration: 7000,
          });
        });
    },
  },
};
</script>
<style lang="css">
.mainDiv {
  margin-top: -16%;
  position: relative;
}


.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}



.AssetStore {
  background: transparent linear-gradient(181deg, #ff1919 0%, #6012e8 100%);
  font-family: Peace Sans;
  font-size: 43px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.second {
  width: 100%;
}

.PrimaryButton {
  margin: 0 !important;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.titles {
  display: flex;
  justify-content: space-around;
  margin: 56px 0 0 -60px;
}

.titleLight {
  color: #000748 !important;
}

.title {
  text-align: left;
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.circles {
  position: absolute;
}

.video {
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  object-fit: none;
}

.circleLight {
  background: transparent linear-gradient(180deg, #e697ff 0%, #d9ddff00 100%) 0%
    0% !important;
  opacity: 0.15 !important;
}

.circle {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%);
  opacity: 0.5;
  border-radius: 50%;
  top: 620px;
  left: -173.95562744140625px;
  width: 800px;
  height: 500px;
}

.circle1 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #06009f 100%);
  opacity: 0.4;
  right: 142px;
  position: absolute;
  width: 486px;
  height: 430px;
  position: absolute;
  border-radius: 500%;
}

.circle3 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #060060 100%);
  opacity: 0.4;
  filter: blur(10px);
  right: 50px;
  position: absolute;
  bottom: -24px;
  width: 23%;
  height: 20%;
  border-radius: 500%;
}

.RETROVERSE {
  font-size: 60px;
  background: transparent linear-gradient(184deg, #ff1919 0%, #6012e8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 80%;
  letter-spacing: 0.2rem;
  font-family: Peace Sans;
  text-align: center;
}

.circle2 {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.35;
  border-radius: 50%;
  top: 930px;
  left: 674px;
  width: 1121px;
  height: 1121px;
  right: 50px;
}



.lightBackground {
  background-color: #fff !important;
}

.background {
  background-color: #000c4a;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -1%;
  flex-direction: column;
}


.contact .input:focus {
  color: #fff !important;
  opacity: 0.7;
}

.form-control:focus {
  border-color: #060060 !important;
}
.select-input select {
  height: auto !important;
  padding: 14px 50.5px 14px 20px !important;
}
.select-input .inputLight {
  color: #ff53bb !important;
}
.select-input option {
  color: #000;
}
/* .contact .input-contact:focus {
  color: #fff !important;
  opacity: 0.7;
} */
.cart-ambassador {
  border-radius: 25px;
}
.btn-ambassador-page {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0%
    0% no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  border-width: 0px;
  padding: 19px 28.5px;
  margin: 0px 10px 0px 0px;
  width: 200px;
  color: #fff;
  font: normal normal normal 16px Lato;
  font-weight: 600;
  float: right;
}
</style>
