<template lang="html">
  <div class="home" @click.right.prevent>
    <div class="home-container game-elements-page">
      <HeroPlayer />
      <UnderHeroPlayer />
    </div>
  </div>
</template>
<script>

import HeroPlayer from "../components/Player/HeroPlayer.vue";
import UnderHeroPlayer from "../components/Player/UnderHeroPlayer.vue";



export default {
  name: "player",
  components: {
    HeroPlayer,
    UnderHeroPlayer
  },
  mounted() {
    this.$route.meta.title = "player"
    this.$route.meta.description = " "
    this.$route.meta.keywords = " "

  },
     computed:{
       currentRouteName() {
         if(this.$route.name=="NotFound"){
           return false
        }else {
           return true
        }
    }
    
  },
  data() {
      return {
        dataToDisplay:{},
         items : [
          {
        text:"Home",
        to:"/"
        },
        {
        text:"player",
        to:"/player"
        },
        ]
      }
      },
};

</script>
<style lang="css">
.container-retro{
  max-width: 100% !important;
}
</style>
