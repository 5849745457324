<template>
  <button :class="type" @click="onClick">
      <span class="text">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    label: String,
    type: String,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style>
.primary {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0% 0%
    no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  border-width: 0px;
  padding: 14.5px 28.5px;
  margin: 0px 10px 0px 0px;
  width: 150px;
  z-index: 10;
}
.primary:hover {
  background-position: 200% 100%;
}
.secondary:hover {
  background-position: 100% 0;
}

.secondary {
  background: transparent linear-gradient(162deg, #e32525 0%, #4015d5 60%, #4015d5 50%) 0%
    0% no-repeat padding-box;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 17px #0000006e;
  border-radius: 30px;
  border-width: 0px;
  padding: 14.5px 28.5px;
  margin: 0px 00px 0px 10px;
  width: 150px;
}
.third {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0% 0%
    no-repeat;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  box-shadow: 0px 0px 10px #ffffff33;
  border-radius: 30px;
  border-width: 0px;
  padding: 14.5px 28.5px;
  margin: 0px 10px 0px 0px;
  width: 200px;
}
.third:hover {
  background-position: 100% 100%;
}

.text {
  color: white;
  text-align: center;
  font: normal normal normal 16px Lato;
  font-weight: 600;
}
</style>