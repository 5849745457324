<template>
  <div class="mainDiv general-condition">
    <Wave v-if="!this.$store.state.isLightTheme" />
    <WaveLight v-if="this.$store.state.isLightTheme"></WaveLight>
    <div
      class="background"
      :class="{ lightBackground: this.$store.state.isLightTheme }"
    >
      <div class="circles">
        <figure
          class="circle"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle1"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
        <figure
          class="circle2"
          :class="{ circleLight: this.$store.state.isLightTheme }"
        />
      </div>
   <div class="second-padding general-condition-text text-justify">
    <h2>Definitions</h2>
    <p>
        <b>Client:</b> Any professional or natural person capable in the sense of Articles 1123 and following of the Civil Code, or legal entity, who visits the Site subject to these general conditions.<br />
        <b>Services:</b> 
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        provides Clients with:
    </p>

    <p>
        <b>Content:</b> All the elements constituting the information present on the Site, notably texts - images - videos.
    </p>

    <p>
        <b>Customer Information:</b> Hereinafter referred to as "Information(s)" which corresponds to all personal data likely to be held by 
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        for the management of your account, the management of customer relations and for analysis and statistics purposes.
    </p>

    <p>
        <b>User:</b> Internet user connecting to or using the aforementioned site.
    </p>
    <p>
        <b>Personal Information:</b> "Information that allows, in any form whatsoever, directly or indirectly, the identification of the natural persons to which they apply" (Article 4 of Law No. 78-17 of January 6, 1978).
    </p>
    <p>
        The terms "personal data", "data subject", "processor", and "sensitive data" have the meaning defined by the General Data Protection Regulation (GDPR: No. 2016-679).
    </p>

    <h2>1. Presentation of the website.</h2>
    <p>
        Under Article 6 of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy, users of the website
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        are informed of the identity of the various stakeholders involved in its creation and follow-up:
    </p>
    <p>
        <strong>Owner:</strong> SAS (SASU) RETROVERSE, VAT Number: FR44950957100
        <br />
        <strong>Webmaster:</strong> Webmaster – services@retroverse.game<br />
        <strong>Host:</strong> ovh – 2 rue Kellermann 59100 Roubaix 1007<br />
        <strong>Data Protection Officer:</strong> Name (Data Protection Officer) – services@retroverse.game<br />
    </p>

    <h2>2. General conditions of use of the site and services offered.</h2>

    <p>
        The Site constitutes a work of the mind protected by the provisions of the Intellectual Property Code and the applicable International Regulations. The Client may not in any way reuse, assign or exploit for its own account all or part of the elements or works of the Site.
    </p>

    <p>
        The use of the website
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        implies full and complete acceptance of the following general conditions of use. These terms of use may be modified or supplemented at any time, so users of the website
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        are invited to consult them regularly.
    </p>

    <p>
        This website is normally accessible to users at any time. However, an interruption for technical maintenance reasons may be decided by
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>,
        which will then endeavor to communicate the dates and times of the intervention to users in advance. The website
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        is regularly updated by
        <a href="https://www.retroverse.game">https://www.retroverse.game</a>
        responsible. Similarly, the legal notices may be modified at any time: they are nevertheless binding on the user, who is invited to refer to them as often as possible in order to become aware of them.
    </p>

    <h2>3. Description of the services provided.</h2>


        <p>
    The website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    aims to provide information about all activities of the company.
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    strives to provide as accurate information as possible on the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    . However, it cannot be held responsible for omissions, inaccuracies, and deficiencies in updates, whether they are its own or from third-party partners providing this information.
</p>

<p>
    All information indicated on the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is given for indicative purposes and may evolve. Furthermore, the information on the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is not exhaustive. It is provided subject to changes that may have been made since they were posted.
</p>

<h2>4. Contractual limitations on technical data.</h2>

<p>
    The website uses JavaScript technology. The Internet site cannot be held responsible for material damage related to its use. In addition, the user of the website undertakes to access the site using recent equipment, free of viruses and with an up-to-date latest-generation browser. The website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is hosted by a provider on the territory of the European Union in accordance with the provisions of the General Data Protection Regulation (GDPR: No. 2016-679).
</p>

<p>
    The objective is to provide a service with the best accessibility rate. The host ensures continuity of service 24 hours a day, every day of the year. However, it reserves the right to interrupt the hosting service for the shortest possible durations, in particular for maintenance purposes, improvement of its infrastructures, failure of its infrastructures, or if the Services generate traffic deemed abnormal.
</p>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    and the host cannot be held responsible for malfunctioning of the Internet network, telephone lines, or computer and telephone equipment, especially related to network congestion preventing access to the server.
</p>

<h2>5. Intellectual property and counterfeits.</h2>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    owns the intellectual property rights and holds usage rights for all elements accessible on the internet site, particularly texts, images, graphics, logos, videos, icons, and sounds. Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, regardless of the means or process used, is prohibited, except with the prior written authorization of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    .
</p>

<p>
    Any unauthorized use of the site or any of its elements will be considered as constituting an infringement and will be prosecuted in accordance with the provisions of Articles L.335-2 and following of the Intellectual Property Code.
</p>

<h2>6. Limitations of liability.</h2>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    acts as the publisher of the site.
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is responsible for the quality and truthfulness of the Content it publishes.
</p>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    cannot be held responsible for direct and indirect damages caused to the user's equipment when accessing the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    , and resulting either from the use of equipment that does not meet the specifications indicated in point 4, or from the appearance of a bug or incompatibility.
</p>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    also cannot be held responsible for indirect damages (such as loss of market or chance) resulting from the use of the site
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    . Interactive spaces (possibility to ask questions in the contact area) are available to users.
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    reserves the right to remove, without prior notice, any content deposited in this space that contravenes applicable law in France, in particular the provisions relating to data protection. If necessary,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    also reserves the right to question the civil and/or criminal liability of the user, particularly in the event of a message of a racist, offensive, defamatory, or pornographic nature, regardless of the medium used (text, photograph, etc.).
</p>

<h2>7. Management of personal data.</h2>

<p>
    The Client is informed of the regulations concerning marketing communication, the Law of June 21, 2014, for confidence in the Digital Economy, the Data Protection Act of August 6, 2004, as well as the General Data Protection Regulation (GDPR: No. 2016-679).
</p>

<h3>7.1 Data collection controllers</h3>

<p>
    For Personal Data collected as part of the creation of the User's personal account and during their navigation on the Site.
</p>

<p>
    As the data controller for the processing of the data it collects,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    undertakes to comply with the legal provisions in force. It is particularly up to the Client to establish the purposes of its data processing, to provide its prospects and customers, from the collection of their consents, with complete information on the processing of their personal data and to maintain a register of processing activities in compliance with reality. Whenever
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    processes Personal Data,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    takes all reasonable measures to ensure the accuracy and relevance of the Personal Data with regard to the purposes for which
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    processes them.
</p>

<h3>7.2 Purpose of the collected data</h3>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    may process all or part of the data for the following purposes:
</p>

<ul>
    <li>
        to enable navigation on the Site and the management and traceability of the services ordered by the user: connection and usage data of the Site, invoicing, order history, etc.
    </li>

    <li>
        to prevent and fight against computer fraud (spamming, hacking, etc.): computer hardware used for navigation, IP address, password (hashed)
    </li>

    <li>
        to improve navigation on the Site: connection and usage data
    </li>

    <li>
        to conduct optional satisfaction surveys on
        <a href="https://www.retroverse.game">https://www.retroverse.game</a
        >: email address
    </li>
    <li>
        to conduct communication campaigns (sms, email): phone number, email address
    </li>
</ul>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    does not commercialize your personal data, which are therefore used only out of necessity or for statistical and analysis purposes.
</p>

<h3>7.3 Right of access, rectification, and objection</h3>

<p>
    In accordance with current European regulations, Users of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    have the following rights:
</p>
<ul>
    <li>
        the right of access (Article 15 GDPR) and rectification (Article 16 GDPR), updating, completeness of Users' data, the right to lock or erase personal data (Article 17 GDPR) when they are inaccurate, incomplete, ambiguous, outdated, or whose collection, use, communication, or storage is prohibited
    </li>

    <li>
        the right to withdraw consent at any time (Article 13-2c GDPR)
    </li>

    <li>
        the right to limit the processing of Users' data (Article 18 GDPR)
    </li>

    <li>
        the right to object to the processing of Users' data (Article 21 GDPR)
    </li>

    <li>
        the right to portability of the data that Users have provided, when this data is subject to automated processing based on their consent or a contract (Article 20 GDPR)
    </li>

    <li>
        the right to define the fate of Users' data after their death and to choose to whom
        <a href="https://www.retroverse.game"
            >https://www.retroverse.game</a
        >
        should communicate (or not) their data to a third party that they have previously designated
    </li>
</ul>

<p>
    As soon as
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    becomes aware of the death of a User and in the absence of instructions from them, it undertakes to destroy their data, unless their retention proves necessary for evidentiary purposes or to fulfill a legal obligation.
</p>

<p>
    If the User wishes to know how
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    uses their Personal Data, request their rectification, or oppose their processing, the User can contact
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    in writing at the following address:
</p>

Retroverse – services@retroverse.game ( Data Protection Officer )

<p>
    In this case, the User must indicate the Personal Data that they would like
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    to correct, update, or delete, by identifying themselves precisely with a copy of an identity document (identity card or passport).
</p>

<p>
    Requests for the deletion of Personal Data will be subject to the obligations imposed on
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    by law, in particular with regard to the retention or archiving of documents. Finally, Users of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    can file a complaint with the supervisory authorities, including the CNIL (https://www.cnil.fr/fr/plaintes).
</p>

<h3>7.4 Non-communication of personal data</h3>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    prohibits the processing, hosting, or transfer of the Information collected on its Clients to a country located outside the European Union or recognized as "not adequate" by the European Commission without informing the client beforehand. However, <a href="https://www.retroverse.game">https://www.retroverse.game</a> remains free to choose its technical and commercial subcontractors, provided that they provide sufficient guarantees with regard to the requirements of the General Data Protection Regulation (GDPR: No. 2016-679).
</p>

<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    undertakes to take all necessary precautions to preserve the security of the Information and in particular that they are not communicated to unauthorized persons. However, if an incident affecting the integrity or confidentiality of the Client's Information is brought to the attention of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    , it must inform the Client as soon as possible and communicate the corrective measures taken. Furthermore,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    does not collect any "sensitive data".
</p>

<p>
    The Personal Data of the User may be processed by subsidiaries of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    and subcontractors (service providers), exclusively in order to achieve the purposes of this policy.
</p>
<p>
    Within the limits of their respective attributions and for the purposes mentioned above, the main persons likely to have access to the data of the Users of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    are mainly the agents of our customer service.
</p>

<div ng-bind-html="rgpdHTML">
    <h3>7.5 Types of collected data</h3>
    <p>
        Regarding users of a Site
        <a href="https://www.retroverse.game">https://www.retroverse.game</a
        >, we collect the following data which are essential for the functioning of the service and which will be kept for a maximum period of 36 months after the end of the contractual relationship:<br />Name, first name, address, email
    </p>
    <p>
        <a href="https://www.retroverse.game"
            >https://www.retroverse.game</a
        >
        also collects information that allows us to improve the user experience and provide contextualized advice:<br />Name, first name, address, opening hours, email
    </p>
    <p>
        This data is kept for a maximum period of 36 months after the end of the contractual relationship.
    </p>
</div>
<h2>8. Incident Notification</h2>
<p>
    Despite all efforts, no method of transmission over the Internet and no method of electronic storage is completely secure. Therefore, we cannot guarantee absolute security. If we become aware of a security breach, we will notify the affected users so that they can take appropriate measures. Our incident notification procedures take into account our legal obligations, whether at the national or European level. We are committed to fully informing our customers of all security-related issues with their account and providing them with all necessary information to help them comply with their own regulatory reporting obligations.
</p>
<p>
    No personal information of the user of the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is published without the user's knowledge, exchanged, transferred, assigned, or sold on any medium to third parties. Only in the event of the acquisition of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    and its rights would the transmission of such information to the potential acquirer be allowed, who would in turn be subject to the same obligation of data retention and modification vis-à-vis the user of the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>.
</p>
<h3>Security</h3>
<p>
    To ensure the security and confidentiality of Personal Data and Health Data,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    uses networks protected by standard devices such as firewalls, pseudonymization, encryption, and passwords.
</p>
<p>
    When processing Personal Data,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    takes all reasonable measures to protect them against any loss, misuse, unauthorized access, disclosure, alteration, or destruction.
</p>
<h2 id="cookies">9. Hyperlinks, "Cookies," and Internet Tags</h2>
<p>
    The website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    contains a number of hyperlinks to other websites, set up with the permission of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>.
    However,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    does not have the ability to verify the content of the visited sites and therefore assumes no responsibility for this.
</p>
<p>
    Unless you decide to disable cookies, you agree that the site may use them. You can disable these cookies at any time and free of charge using the deactivation options provided and mentioned below, knowing that this may reduce or prevent access to all or part of the services offered by the site.
</p>
<h3>9.1. "Cookies"</h3>
<p>
    A "cookie" is a small information file sent to the User's browser and saved on the User's device (e.g., computer, smartphone) ("Cookies"). This file includes information such as the User's domain name, Internet service provider, User's operating system, and the date and time of access. Cookies pose no risk of damaging the User's device.
</p>
<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    may process information from the User regarding their visit to the Site, such as the pages viewed and searches conducted. This information allows
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    to improve the content of the Site and the User's navigation.
</p>
<p>
    Cookies that facilitate navigation and/or the provision of services offered by the Site allow the User to configure their browser to decide whether or not to accept them, so that Cookies are either saved on the device or rejected, either systematically or depending on their source. The User can also configure their navigation software to prompt them to accept or reject Cookies on a case-by-case basis before a Cookie is likely to be stored on their device. In this case, however, the User should be aware that not all features of their navigation software may be available.
</p>
<p>
    If the User refuses to store Cookies on their device or browser or deletes those that are stored there, the User is informed that their navigation and experience on the Site may be limited. This could also be the case when
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    or one of its service providers cannot recognize, for technical compatibility purposes, the type of browser used by the device, the language and display settings, or the country from which the device seems to be connected to the Internet.
</p>
<p>
    In this case,
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    declines all responsibility for the consequences related to the degraded functioning of the Site and the services possibly offered by
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>,
    resulting from (i) the refusal of Cookies by the User, or (ii) the inability of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    to store or access the Cookies necessary for their operation due to the User's choice. Each browser's configuration is different for managing Cookies and User preferences. This is described in the browser's help menu, which will explain how the User can modify their Cookie preferences.
</p>
<p>
    At any time, the User can choose to express and modify their Cookie preferences.
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    may also use the services of external providers to help collect and process the information described in this section.
</p>
<p>
    By clicking on the dedicated icons for the social media networks Twitter, Facebook, Linkedin, and Google Plus on the website of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    or in its mobile application, and if the User has accepted the deposit of cookies by continuing their navigation on the website or mobile application of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>,
    Twitter, Facebook, Linkedin, and Google Plus may also deposit cookies on your devices (computer, tablet, mobile phone).
</p>
<p>
    These types of cookies are only deposited on your devices if you consent, by continuing your navigation on the website or mobile application of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>.
    However, at any time, the User can withdraw their consent for
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    to deposit this type of cookies.
</p>
<h3>Article 9.2. Internet Tags ("Tags")</h3>
<p>
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    may occasionally use Internet tags (also called "tags," action tags, one-pixel GIFs, transparent GIFs, invisible GIFs, and one-to-one GIFs) and deploy them through a specialist web analysis partner located abroad (and therefore store the corresponding information, including the User's IP address) in a foreign country.
</p>
<p>
    These tags are placed both in online advertisements that allow users to access the Site and on different pages of the Site.
</p>
<p>
    This technology allows
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    to evaluate visitor responses to the Site and the effectiveness of its actions (for example, the number of times a page is opened and the information consulted), as well as the User's use of this Site.
</p>
<p>
    The external provider may collect information about visitors to the Site and other websites using these tags, create reports on the Site's activity for the attention of
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>,
    and provide other services related to its use and the use of the Internet.
</p>
<h2>10. Applicable Law and Jurisdiction.</h2>
<p>
    Any dispute related to the use of the website
    <a href="https://www.retroverse.game">https://www.retroverse.game</a>
    is subject to French law.
</p>
</div>
</div>
    </div>
  </div>
</template>
<script>
import Wave from "../../assets/svg/wave.svg";
import WaveLight from "../../assets/svg/waveLight.svg";
import Axe from "../../assets/svg/axe.svg";
import Coin from "../../assets/svg/coin.svg";
import Tree from "../../assets/svg/tree.svg";
import Balloon from "../../assets/svg/balloon.svg";
import WhiteLogo from "../../assets/svg/Logo.svg";
import LogoLight from "../../assets/svg/logoLight.svg";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import Card from "../Card/Card.vue";

export default {
  name: "UnderHeroSection",
  components: {
    Wave,
    WhiteLogo,
    PrimaryButton,
    Card,
    Tree,
    Axe,
    Balloon,
    Coin,
    WaveLight,
    LogoLight,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    subscribe() {},
  },
};
</script>
<style lang="css">
.mainDiv {
  margin-top: -16%;
  position: relative;
}
.row-container-player {
  display: flex;
  flex-direction: row;
  margin: 8% 0px;
  width: 1100px;
  justify-content: space-between;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}


.AssetStore {
  background: transparent linear-gradient(181deg, #ff1919 0%, #6012e8 100%);
  font-family: Peace Sans;
  font-size: 43px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.second {
  width: 100%;
}

.PrimaryButton {
  margin: 0 !important;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.titles {
  display: flex;
  justify-content: space-around;
  margin: 56px 0 0 -60px;
}

.titleLight {
  color: #000748 !important;
}

.title {
  text-align: left;
  font-family: Peace Sans;
  font-size: 24px;
  letter-spacing: 1px;
  color: #ffffff;
}

.circles {
  position: absolute;
}

.video {
  align-self: center;
  border-radius: 30px;
  margin-top: 40px;
  object-fit: none;
}

.circleLight {
  background: transparent linear-gradient(180deg, #e697ff 0%, #d9ddff00 100%) 0%
    0% !important;
  opacity: 0.15 !important;
}

.circle {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%);
  opacity: 0.5;
  border-radius: 50%;
  top: 620px;
  left: -173.95562744140625px;
  width: 800px;
  height: 500px;
}

.circle1 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #06009f 100%);
  opacity: 0.4;
  right: 142px;
  position: absolute;
  width: 486px;
  height: 430px;
  position: absolute;
  border-radius: 500%;
}

.circle3 {
  background: transparent linear-gradient(162deg, #bc00bc 0%, #060060 100%);
  opacity: 0.4;
  filter: blur(10px);
  right: 50px;
  position: absolute;
  bottom: -24px;
  width: 23%;
  height: 20%;
  border-radius: 500%;
}

.RETROVERSE {
  font-size: 60px;
  background: transparent linear-gradient(184deg, #ff1919 0%, #6012e8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 80%;
  letter-spacing: 0.2rem;
  font-family: Peace Sans;
  text-align: center;
}

.circle2 {
  background: transparent linear-gradient(180deg, #670187 0%, #040a4800 100%) 0%
    0% no-repeat padding-box;
  opacity: 0.35;
  border-radius: 50%;
  top: 930px;
  left: 674px;
  width: 1121px;
  height: 1121px;
  right: 50px;
}


.lightBackground {
  background-color: #fff !important;
}

.background {
  background-color: #000c4a;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -1%;
  flex-direction: column;
}

.general-condition a {
  color: #bc00bc !important;
}
.lightBackground .general-condition-text {
  color: #060060 !important;
}
.general-condition-text {
  color: #fff;
}

.general-condition-text h2 {
  font-family: "Lato" !important;
}
.general-condition-text h3 {
  font-family: "Lato" !important;
  font-size: 1.5rem;
}
</style>
