<template lang="html">
  <div class="home" @click.right.prevent>
    <div class="home-container game-elements-page">
      <HeroCondition/>
      <GeneralCondition />
    </div>
  </div>
</template>
<script>

import GeneralCondition from "../components/generalCondition/GeneralCondition.vue";
import HeroCondition from "../components/generalCondition/HeroCondition";



export default {
  name: "assetStore",
  components: {
    GeneralCondition,
    HeroCondition
  },
  mounted() {
    this.$route.meta.title = ""
    this.$route.meta.description = " "
    this.$route.meta.keywords = " "

  },
     computed:{
       currentRouteName() {
         if(this.$route.name=="NotFound"){
           return false
        }else {
           return true
        }
    }
    
  },
  data() {
      return {
        dataToDisplay:{},
         items : [
          {
        text:"Home",
        to:"/"
        },
        {
        text:"assetStore",
        to:"/assetStore"
        },
        ]
      }
      },
};

</script>
<style lang="css">
.container-retro{
  max-width: 100% !important;
}
</style>
