<template>
  <button @click="onClick" class="basicIcon" :class="{ basicIconLight: this.$store.state.isLightTheme }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BasicIcon",
  props: {
    onClick: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style>
.basicIconLight {
  background: #fff !important;
  border: 1px solid #000748;
}

.basicIcon {
  background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0% 0%
    no-repeat;
  border-radius: 80px;
  overflow: hidden;
  background-size: 100% 200%;
  background-position: 100%;
  transition: background-position 275ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
  margin: 10px;
}
.basicIcon:hover {
  background-position: 200% 100%;
}

@media only screen and (max-width: 415px) {
  .basicIcon {
    background: transparent linear-gradient(173deg, #e32525 0%, #4015d5 100%) 0% 0%
      no-repeat;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 15px;
    margin: 2px;
  }
}
</style>