<template>
  <div
    id="container"
    class="home-content pt-250 second-padding"
    :class="{ lightThemed: this.$store.state.isLightTheme }"
  >
    <b-row>
      <b-col col lg="12" md="12" sm="12" cols="12" class="text-center padding-sub-page">
        <h3 data-aos-duration="1250" data-aos="zoom-in-down" data-aos-once="true" class="header-subtitle">
        GET IN TOUCH 
        </h3>
        <h2 data-aos-duration="1250" data-aos="zoom-in-down" data-aos-once="true" class="home-content-h2">
            CONTACT
        </h2>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  name: "ContactHeader",
  components: { PrimaryButton },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    subscribe() {},
  },
};
</script>
<style lang="css">


.lightThemed {
  background-image: url("../../assets/img/light_bg.png") !important;
}

.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.home-content {
  background-image: url("../../assets/img/dark_bg.png");
  background-position: center;
  background-size: cover;
}

</style>
